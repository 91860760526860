<template>
  <div class="px-4 pb-4">
    <v-row dense>
      <v-col>
        <v-data-table
          :loading="!isLoaded && isLoading('get')" loading-text="Carregando Registro de Trocas...." :search="search" :items="trocas" :headers="headers" dense  >
          <template v-slot:top>
            <!-- Linha de Pesquisar, atualizar e criar novas trocas -->
            <v-row>
              <!-- Campo de Pesquisa do endereço -->
              <v-col cols="5">
                <v-text-field dense label="Pesquisar" outlined v-model="search" class="mx-0" append-icon="mdi-magnify" clearable />
              </v-col>
              <v-col class="text-end">
                <!-- Botão de Atualizar a tela -->
                <v-btn class="mr-4 elevation-0" text  @click="atualizar" >
                  <v-icon left> mdi-reload </v-icon> atualizar página
                </v-btn>
                <!-- Botão de Criar "+ NOVO" novo endereço -->
                <v-btn color="success" class="elevation-0"  @click="dialog.create = true" >
                  <v-icon left> mdi-plus </v-icon> NOVO
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
              <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
            </v-chip>
          </template>
          <template v-slot:[`item.data_troca`]="{ item }">
            <span>{{ formatDate(item.data_troca) }}</span>
          </template>
          <template v-slot:[`item.data_proxima_troca`]="{ item }">
            <span>{{ formatDate(item.data_proxima_troca) }}</span>
          </template>
          <template v-slot:[`item.tqt`]="{ item }">
            <span>{{ item.tqt ? "TQT": "GTT" }}</span>
          </template>

          <template v-slot:no-data>
            <span> Nenhuma Troca encontrada. </span>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <span>
              <v-btn icon @click="onSelectTroca(item, 'update')">
                <v-icon color="green">mdi-pencil</v-icon>
              </v-btn>
            </span>
          </template>
        </v-data-table>
      </v-col>
      <!-- Nova Troca -->
      <v-dialog v-if="dialog.create" v-model="dialog.create" max-width="1000">
        <v-card>
          <v-card-title class="blod_color">
            Nova Troca de Dispositivo:
            <v-spacer />
            <!-- Botão e ícone de fechar a tela -->
            <v-btn icon @click="dialog.create = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <!-- Abre a página de solicitação de novo equipamento -->
          <v-card-text>
            <NovaTroca :paciente="paciente.id"  :onSubmit="saveTrocas" />
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Editar Troca -->
      <v-dialog v-if="dialog.update" v-model="dialog.update" max-width="1000">
        <v-card>
          <v-card-title class="blod_color">
            Editar Troca de Dispositivo:
            <v-spacer />
            <!-- Botão e ícone de fechar a tela -->
            <v-btn icon @click="dialog.update = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <!-- Abre a página de edição de troca -->
          <v-card-text>
            <EditarTroca :paciente_id="paciente.id" :troca="selectedTroca" :onSubmit="updateTroca" :ativoOptions="ativoOptions"/>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import api from '@/http'
import UtilsFunc from '@/service/utilsFunc'
import NovaTroca from "./NovaTroca.vue";
import EditarTroca from "./EditarTroca.vue";

const { withCRUDUtils } = UtilsFunc

export default {
  name: "EquipamentoPaciente",
  components: { NovaTroca, EditarTroca },
  props: {
    paciente: {
      type: Object
    },
    onUpdate: Function
  },
  data() {
    return withCRUDUtils({
      trocas: [],
      isLoaded: false, 
      selectedTroca: null,
      dialog: {
        create: false,
        update: false
      },
      ativoOptions: [
        { nome: "Ativo", value: true },
        { nome: "Inativo", value: false },
      ],
      selectedEquipamento: null,
      headers: [
        { text: "Editar", value: "edit", width: 80 },
        { text: "Id", value: "id", width: 80 },
        { text: "TQT ou GTT", value: "tqt"},
        { text: "Data da Troca", value: "data_troca" },
        { text: "Data da Próxima Troca", value: "data_proxima_troca" },
        { text: "Status", value: "ativo" }
      ],
    });
  },
  methods: {
    async getTrocaDispositivo() {
      const LOADING_NAME = 'get'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get(`/pacientes/troca-dispositivos/?paciente=${this.paciente.id}`)
        this.trocas = data.sort((a, b) => new Date(b.data_troca) - new Date(a.data_troca));
        // console.log('Trocas--> ', this.trocas)
        this.isLoaded = true 
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, false)
      }
    },
    async updateTroca(fields) {
      const LOADING_NAME = 'put:troca'
      this.setLoading(LOADING_NAME)
      try {
        await api.put(`pacientes/troca-dispositivo/${fields.id}/`, fields)
        await this.getTrocaDispositivo()
        this.$toast.success('Troca de Dispositivo alterada com sucesso!')
        this.dialog.update = false
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
        this.dialog.update = false
      }
    },
    async saveTrocas(field) {
      const LOADING_NAME = 'post:troca';
      this.setLoading(LOADING_NAME);
      try {
        await api.post(`/pacientes/troca-dispositivos/`, field);
        await this.getTrocaDispositivo();
        this.$toast.success('Troca de Dispositivo salva com sucesso!');
        this.dialog.create = false;
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, false);
      }
    },
    onSelectTroca(troca, action) {
      if (action === 'update') {
        this.selectedTroca = troca; 
        this.dialog.update = true;
      }
    },
    formatDate(isoString) {
      if (!isoString) return '';
      const date = new Date(isoString);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // Ajuste para compensar a diferença do fuso horário
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    async atualizar() {
      this.isLoaded = false;
      await this.getTrocaDispositivo();
    },

  },
  async mounted() {
    await this.getTrocaDispositivo();
  },
}
</script>

<style scoped>
.text-green {
  color: green;
}

.text-red {
  color: red;
}
</style>
