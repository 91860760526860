<template>
  <div class="pa-4">
    <v-form @submit.prevent="localOnSubmit" ref="formRef">
      <v-row dense>
        <!-- Seleção de TQT ou GTT -->
        <v-col cols="3">
          <v-select
            dense
            label="TQT ou GTT"
            v-model="localTroca.tqt"
            :items="[{ nome: 'TQT', value: true }, { nome: 'GTT', value: false }]"
            item-value="value"
            item-text="nome"
            outlined
            :rules="tqtRules"
          ></v-select>
        </v-col>

        <!-- Data da Troca -->
        <v-col cols="3">
          <v-text-field
            dense
            label="Data da Troca"
            v-model="localTroca.data_troca"
            type="date"
            outlined
            required
            :rules="requiredField"
          ></v-text-field>
        </v-col>

        <!-- Data da Próxima Troca -->
        <v-col cols="3">
          <v-text-field
            dense
            label="Data da Próxima Troca"
            v-model="localTroca.data_proxima_troca"
            type="date"
            outlined
            required
          ></v-text-field>
        </v-col>

        <!-- Status Ativo/Inativo -->
        <v-col cols="3">
          <v-select
            dense
            outlined
            label="Status"
            v-model="localTroca.ativo"
            :items="ativoOptions"
            item-value="value"
            item-text="nome"
            required
            :rules="requiredField"
          ></v-select>
        </v-col>
      </v-row>

      <!-- Botão de Salvar -->
      <v-row dense>
        <v-col class="text-end">
          <ConfirmButton color="success" :loading="isSubmitting">
            Salvar
          </ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ConfirmButton from '../../../../ConfirmButton.vue';
import UtilsFunc from '../../../../../service/utilsFunc';

const { withCRUDUtils } = UtilsFunc;

export default {
  name: "NovaTroca",
  components: { ConfirmButton },
  props: {
    onSubmit: Function,
    paciente: Number,
    ativoOptions: {
      type: Array,
      default: () => [
        { value: true, nome: 'Ativo' },
        { value: false, nome: 'Inativo' },
      ],
    },
  },
  data() {
    return withCRUDUtils({
      localTroca: {
        tqt: null, // Campo para TQT ou GTT
        data_troca: '', // Campo para a data da troca
        data_proxima_troca: '', // Campo para a data da próxima troca
        ativo: null, // Campo para ativo/inativo
      },
      isSubmitting: false,
      requiredField: [(v) => !!v || "Este campo é obrigatório"], // Validação para campos obrigatórios
      tqtRules: [(v) => v === true || v === false || "O campo TQT ou GTT é obrigatório"], // Validação específica para o campo booleano
    });
  },
  methods: {
    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }

      const field = {
        paciente: this.paciente,
        tqt: this.localTroca.tqt,
        data_troca: this.localTroca.data_troca,
        data_proxima_troca: this.localTroca.data_proxima_troca ? this.localTroca.data_proxima_troca : null,
        ativo: this.localTroca.ativo,
      };

      this.isSubmitting = true;
      await this.onSubmit(field).finally(() => {
        this.isSubmitting = false;
      });
    },
  },
};
</script>
